import { event } from "vue-gtag";

export default {
  computed: {
    getObjectDataMixin() {
      if (this.$route.name === "Booking") {
        return this.$store.getters['booking/objectData'];
      } else {
        return this.$store.getters['detail/getObjectData'];
      }
    },
    getObjectPriceMixin() {
      if (this.$route.name === "Booking") {
        return this.$store.getters['booking/bookingPrice'];
      } else {
        return this.$store.getters['detail/getObjectPrice'];
      }
    },
    getUserMixin() {
      return this.$store.state.user['userData'];
    },
    getHotelMixin() {
      return this.$store.getters['hotel/hotel'];
    },
    isHotelMixin() {
      return this.getObjectDataMixin?.hotel ? true : false;
    },
    roomsCnt() {
      if (this.$route.name === "Booking") {
        return `${this.getObjectDataMixin?.roomsCnt} (комнат)` || "";
      } else {
        const basicProperties = this.getObjectDataMixin?.properties?.basic_properties?.properties;
        const countRooms = basicProperties ? basicProperties.find(item => item.name === "rooms") : null;
        return countRooms?.value ? `${countRooms?.value} (комнат)` : "";
      }
    },
    sleepingPlaces() {
      const props = this.getObjectDataMixin?.properties?.basic_properties?.properties;
      if(!props) {
        return null;
      }
      const sleepingPlacesCount = props.find((prop) => prop.name === "beds");
      return sleepingPlacesCount;
    },
    priceMixin() {
      // if(this.$route.name === "Hotel") {
      //   return null;
      // };
      return (
        this.getObjectPriceMixin?.price ||
        this.getObjectPriceMixin?.price_default
      );
    },
    instantBooking() {
      return this.getObjectPriceMixin?.is_booking_now;
    },
    discountCalculation() {
      const bonuses = this.getUserMixin?.balance_virtual || 0;
      const price = this.getObjectPriceMixin?.price || this.getObjectPriceMixin?.price_default;
      const tenPercent = Math.floor(price * 0.1)
      if(bonuses > tenPercent) {
        return tenPercent;
      }
      return bonuses;
    },
    titleObjectMixin() {
      if (this.isHotelMixin) {
        if (!this.getObjectDataMixin?.hotel?.title) return "";
        const title = this.getObjectDataMixin?.hotel?.title;
        const typeHotel = this.getObjectDataMixin?.hotel?.type;
        const titleObject = title ? `${typeHotel} ${title}` : typeHotel;
        return String(titleObject);
      }
      const nameObject =
        this.getObjectDataMixin?.properties?.enter_name?.properties?.find(
          (item) => item.name === "name_object"
        )?.value;
      if (nameObject) {
        return String(nameObject);
      };
      return this.getObjectDataMixin?.title || "";
    },
    ecommerceItems() {
      let ecommerceItems = [];
      if (this.$route.name === "Hotel") {
        ecommerceItems = [
          {
            item_name: this.getHotelMixin?.title || "",
            item_id: this.getHotelMixin?.id,
            item_brand: this.getHotelMixin?.location?.location || null,
            item_category: "Отель",
            item_category2: this.getHotelMixin?.typeTitle || "",
            price: null,
            quantity: null,
          }
        ]
      } else {
        const hotelCategory = this.getObjectDataMixin?.hotel?.type;
        const objectType = this.isHotelMixin ? "Отельный объект" : "Отдельный объект";
        const objectCategory = this.isHotelMixin ? hotelCategory : this.getObjectDataMixin?.type_title;
        const objectRooms = this.isHotelMixin ? hotelCategory?.title : this.roomsCnt;
        const location =
          this.$route.name === "Booking"
            ? this.getObjectDataMixin?.location.city
            : this.getObjectDataMixin?.location?.location;
        const quantity = this.getObjectPriceMixin?.detail?.at(0)?.nights;

        ecommerceItems = [
          {
            item_name: this.titleObjectMixin,
            item_id: this.getObjectDataMixin?.id,
            item_brand: location || null,
            item_category: objectType || "",
            item_category2: this.formattedObjectCategory(objectCategory),
            item_category3: this.getObjectDataMixin?.title,
            item_category4: this.instantBooking ? "МБ" : "По запросу",
            item_variant: this.sleepingPlaces ? `Спальных мест: ${this.sleepingPlaces.value}` : "",
            price: this.priceMixin,
            currency: this.getObjectPriceMixin?.currency?.code,
            discount: this.discountCalculation || null,
            quantity: quantity || null,
          },
        ];
      }

      return ecommerceItems;
    },
  },
  methods: {
    dataLayerPush(eventName) {
      const items = this.ecommerceItems;
      event(eventName, {
        items,
      });

      // dataLayer.push({ ecommerce: null });
      // dataLayer.push({
      //   event: eventName,
      //   ecommerce: {
      //     items: this.ecommerceItems || [],
      //   }
      // });
    },

    formattedObjectCategory(category) {
      const objectCategoryMap = {
        "квартиры, апартаменты": "Квартиры и апартаменты",
        "дома, коттеджи целиком": "Дома",
        "отдельные комнаты, номера": "Отдельные комнаты",
        "спальные места": "Спальные места",
        "другое": "Другое",
      };

      if(category && Object.hasOwn(objectCategoryMap, category)) {
        return objectCategoryMap[category];
      }
      return category;
    },
  },
}
